
import React from 'react';
import { Box, List, ListItem, Grid2, ListItemIcon } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png';
import bgmob from '../Images/bgmob.jpg';
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TypographyComponent from '../Components/TypographyComponent';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'; 
import { useMediaQuery } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ogImage from '../Images/logo.svg'

const Service = () => { 
  const isDesktop = useMediaQuery('(min-width:768px)');

  const { serviceName } = useParams();

  useEffect(() => {
    if (serviceName) {
      // Convert serviceName to lowercase hyphenated format for ID matching
      const element = document.getElementById(serviceName.toLowerCase().replace(/\s+/g, '-'));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [serviceName]);


  const listItems1 = ['PPC', 'Content Creation', 'UX- Audit',];
  const listItems2 = ['SEO', 'Email Marketing', 'Brand Management',];
  const listItems3 = ['Social Media Marketing', 'UI-UX Designing'];
  const listItems1Mob = ['PPC', 'SEO', 'Social Media Marketing', 'Content Creation',
    'Email Marketing','UI-UX Designing','UX- Audit', 'Brand Management'];
 
  return (
    <Grid2 container sx={{
      width: '100%', height: '100%', overflow: 'hidden',
      justifyContent: 'center', alignItems: 'center'
    }}>
       <Helmet>
            <title>SEO services & Online Solutions | PPC Canada</title>
            <meta name="description" content="Get expert PPC Canada management team from our certified team. Our team delivers best pay-per-click advertising for businesses." />
            <link rel="canonical" href="https://helpingflock.com/services" />
            <meta property="og:title" content="SEO Services & Online Solutions | PPC Canada" />
            <meta property="og:description" content="Get expert PPC Canada management team from our certified team. Our team delivers best pay-per-click advertising for businesses." />
            <meta property="og:image" content={ogImage} />
            <meta property="og:url" content="https://helpingflock.com/services" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Helping Flock" />
            <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org",
                "@type": "WebPage",
                "name": "SEO services & Online Solutions | PPC Canada",
                "url": "https://helpingflock.com/services",
                "description": "Get expert PPC Canada management team from our certified team. Our team delivers best pay-per-click advertising for businesses.",
                "mainEntityOfPage": "https://helpingflock.com/services",
                "potentialAction": {
                  "@type": "ContactAction",
                  "target": "mailto:contact@helpingflocks.com",
                  "query-input": "required name=email"
                }
              }
            `}
        </script>
       </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>Social media marketing</h1>
    
      <Box sx={{
        display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center',
        alignItems: 'center', position: 'relative'
      }} >
        <Box sx={{
          width: '100%', height: '410px', backgroundImage: `url(${isDesktop ?headerbg:bgmob})`, backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover', position: 'relative', zIndex: 1, justifyContent: 'center', display: 'flex',
          alignItems: 'center', flexDirection: 'column', gap: {sm:'54px',md:'54px',xs:'8px'}
        }} >
          <Box sx={{
            position: 'absolute', top: 0, left: 0, width: '100%',
            height: '100%', backgroundColor: 'rgba(45, 45, 45, 0.48)'
          }} />
          <Header currentPage={'Services'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 2 }} />
          <Box sx={{
            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
            alignItems: 'center'
          }} >
            <Box sx={{
              display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
              alignItems: 'center', maxWidth: '90%', maxHeight: '90%', gap: {md:'32px',sm:'32px',xs:'12px'}
            }} >

              <TypographyComponent component="h2" sx={{
                fontWeight: 500, fontSize:{md:'48px',sm:'48px',xs:'32px'} , color: '#ffffff', zIndex: 9,
                width: '100%', lineHeight: '56px', textAlign: {xs:'start',md:'center',sm:'center'}, paddingLeft:{md:'0px',sm:'0px',xs:'24px'}
              }}>
                Our Services</TypographyComponent>


              <Box sx={{
                width: '100%', display: { md: 'flex', xs: 'none' }, flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start',
                height: '100%', maxWidth: '90%',
              }}>
                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex',
                  flexDirection: 'column', gap: '3px', minWidth: 'fit-content'
                }}>

                  <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '34px',
                    padding: 0
                  }}>
                    {listItems1.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        
                        <TypographyComponent component="h3" sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}>
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'flex', flexDirection: 'column',
                  gap: '3px', paddingLeft: '20%', minWidth: 'fit-content'
                }}>
                  <List sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '34px',
                    padding: 0
                  }}>
                    {listItems2.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                        
                        <TypographyComponent component="h3"
                          sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Box sx={{
                  width: 'calc(100%/3)', height: '100%', justifyContent: 'right', alignItems: 'right', display: 'flex',
                  flexDirection: 'column', paddingLeft: '20%', minWidth: 'fit-content'
                }}>
                  <List
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '34px',
                      padding: 0
                    }}>
                    {listItems3.map((text, index) => (
                      <ListItem key={index} disablePadding
                        sx={{ display: 'flex', alignItems: 'center' }}>
                       
                        <TypographyComponent component="h3"
                          sx={{ fontWeight: 500, fontSize: '24px', color: Colors.white }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>

              </Box>

              <Box sx={{
                width: '100%', display: { md: 'none', xs: 'flex' }, flexDirection: 'row',
                alignItems: 'center', height: '100%', maxWidth: '90%', gap: '10%', maxHeight: '90%',justifyContent:'flex-start'
              }}>
                <Box sx={{
                  width: '100%', height: '100%', justifyContent: 'flex-start', 
                  alignItems: 'flex-start', display: 'flex',
                  flexDirection: 'column', gap: '3px'
                }}>

                  <List>
                    {listItems1Mob.map((text, index) => (
                      <ListItem key={index} disablePadding  sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        marginBottom: index !== listItems1Mob.length - 1 ? '12px' : 0 // Add margin to all except the last item
                      }}>
                       
                        <TypographyComponent component="h3"
                          sx={{ fontWeight: 500, minWidth: 'fit-content',
                            fontSize: '16px', color: Colors.white, lineHeight: '18px' }}
                        >
                          {text}
                        </TypographyComponent>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>

          </Box>
        </Box>

      </Box>

      <Box sx={{
        height: '95%', display: 'flex', width: '100%', maxWidth: '90%', gap: {sm:'12px',md:'12px',xs:'8px'},
        paddingTop: {sm:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
      }}>
        <Box id="ppc" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'}, paddingTop:{sm:'20px',md:'24px',xs:'8px'} }}>
          <TypographyComponent component="h3"  sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Pay-Per-Click (PPC) Advertising
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>At Helping Flocks, our PPC Canada advertising services provide targeted traffic and instant results. We create effective ads to ensure your brand reaches the right audience at the right time. From keyword research to bid management, we handle everything to deliver the best return on investment. Using a data-driven approach, PPC constantly monitors and optimizes your campaigns to improve visibility and traffic through continuous testing. Whether it’s Google Ads Canada, social media platforms, or the display network, our goal is to bring you measurable success in a highly competitive digital landscape.
          </TypographyComponent>
        </Box>
        <Box id="seo" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'} }}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Search Engine Optimization (SEO)
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400,fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
           In the implemented SEO service, visibility to your website is possible. As far as the social media agency in Toronto is concerned, social media strategy is also included within the purpose of enhancing one's SEO efforts thus maximizing the reach of a brand. Further, we specialize with the practice of optimizing websites to achieve higher rankings on organic results so that one's business may be noticed as potential customers search via relevant keywords.
           <TypographyComponent component="p" sx={{
            fontWeight: 400,fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>The work will involve on-page SEO, techno-optimization, and link building, which will all be done strategically to improve the performance of your website. We provide continuous SEO support with analysis of various site metrics which allows us to adjust the strategies in accordance with ongoing trends of search engines.</TypographyComponent>
         
         <TypographyComponent component="p" sx={{
            fontWeight: 400,fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>By integrating both SEO and social media strategy, we guarantee that your traffic will flow toward sustainable growth, creating qualified leads and expanding your online presence over time. </TypographyComponent>
          </TypographyComponent>
        </Box>
        <Box id="social-media-marketing" sx={{ display: 'flex', flexDirection: 'column',gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'}}}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Social Media Marketing
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            At our services, great social media marketing begins with the provision of marketing solution. As a premier social media agency in Toronto, we provide the widest umbrella, covering every aspect of building a strong online presence, engaging of an audience, and growing your business. Our range of services is constituted in social media, including content creation, curation, social media ad management, and analytics strategies over many platforms—Facebook, Instagram, Twitter, LinkedIn, and others.
            <TypographyComponent component="p" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Our social media marketing strategies are tailored toward your brand's unique voice and goals, ensuring each campaign revolves around reaching out to and resonating with your target audience. Using a variety of tools and latest trends, we amplify the effectiveness of our clients' social media marketing and get additional interactions to build customer loyalty. Google Ads Canada is integrated in for a comprehensive and far-reaching digital marketing strategy. 

          </TypographyComponent>
          
          </TypographyComponent>
        </Box>
        <Box id="content-creation" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'}}}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400},
           fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Content Creation
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            You may tell your stories and convey your vision through our content creation services. We produce best-in-class content-pamphlets, articles, infographics, and videos-to reach your different audiences for manifold timely actions. Our content is commensurate with your marketing objectives to enable you to gain authority and credibility in your industry. Along with great content design, we add SEO services and local SEO-enhancing services that help display your great work widely on the internet. With a team of dedicated writers and designers, we work with you to present your ideas and engage your audience whenever any content is produced. It could be web content, marketing content for social media marketing or email marketing campaigns. Whatever may be the material, we intend to leave our mark on your audience. 
          </TypographyComponent>
        </Box>
        <Box id="email-marketing" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'} }}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight:{ sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            Email Marketing
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
           Build a bridge with your audience through personalized Email Marketing that will incite eager responses. We help you nurture leads, inform clients, and sell products via carefully crafted email messages that appeal to their desires. Our services include list segmentation, content creation, sending, and tracking with performance analysis as it pertains to the campaign objectives. By synthesizing social media marketing with the wider strategy in mind, we develop a cohesive brand message across all the channels. We focus on open rates and conversions; always sending the right message at the right time. This will allow you to establish a productive partnership with your audience, so that they become loyal to your brand at each stage of their journey. 
          </TypographyComponent>
        </Box>
        <Box id="ui-ux-designing" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'}}}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400}, 
          fontSize: {sm:'32px',md:'32px',xs:'14px'}, lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            UI-UX Designing
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight: { sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Delivering superior user experiences through their UI/UX design services, Helping Flocks is a leader in crafting user-friendly interfaces that are not only visually attractive but also highly functional. As one of the Top 10 digital marketing companies in Canada, their designs are based on deep insights into user behavior, ensuring that your website or app meets users' needs with seamless ease. From wireframes to final designs, Helping Flocks ensures that the end product is both visually appealing and easy to navigate. By integrating social media marketing strategies with design, they help businesses engage and sell more effectively. Their focus on user experience leads to higher visitor retention, increased customer satisfaction, and deeper customer loyalty.
          </TypographyComponent>
        </Box>
        <Box id="ui-ux-audit" sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'},paddingTop:{sm:'20px',md:'24px',xs:'8px'} }}>
          <TypographyComponent component="h3" sx={{ fontWeight: {sm:500,md:500,xs:400}, fontSize: {sm:'32px',md:'32px',xs:'14px'}, 
          lineHeight: { sm:'36px',md:'36px',xs:'16px'}, color: Colors.black_80 }}>
            UX - Audit
          </TypographyComponent>
          <TypographyComponent component="h4" sx={{
            fontWeight: 400, fontSize:{ sm:'18px',md:'18px',xs:'12px'},
            lineHeight:{ sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
          }}>
            Optimize your digital products with a service-based UX audit from one of the top performance marketing companies. We conduct comprehensive usability tests on your website or app to identify functionality issues and areas for improvement. Our thorough assessment provides actionable insights that enhance user experience, create satisfied customers, and boost conversions.Our UX audit evaluates everything from navigation and layout to content and functions. By removing roadblocks and streamlining user paths, we ensure more enjoyable and effective experiences for your customers. Whether you need improvements in web development or overall user flow, we help optimize every aspect to drive better performance and customer satisfaction.
          </TypographyComponent>
        </Box>
        <Box id="brand-management" sx={{ display: 'flex', flexDirection: 'column', paddingTop:{sm:'20px',md:'24px',xs:'8px'} }}>
        
  <TypographyComponent component="h2"  sx={{ fontWeight: { sm: 500, md: 500, xs: 400 }, fontSize: { sm: '32px', md: '32px', xs: '14px' }, lineHeight: { sm: '36px', md: '36px', xs: '16px' }, color: Colors.black_80 }}>
    Brand Management
  </TypographyComponent>

  {/* Subtitle */}
  <TypographyComponent component="h3" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80, paddingTop:{sm:'16px',md:'16px',xs:'8px'} }}>
    Your Success, Our Mission
  </TypographyComponent>

  {/* Description */}
  <TypographyComponent component="h4" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
    At Helping flock, we turn your online dreams into reality. We're experts at helping businesses shine in the digital world, without making things complicated.
  </TypographyComponent>
  <TypographyComponent sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
  What we do best:
  </TypographyComponent>
  <List sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap',  }}>
  <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
    <ListItemIcon sx={{ minWidth: '20px' }}>
      <FiberManualRecordIcon sx={{ fontSize: '10px', color: Colors.black_80 }} />
    </ListItemIcon>
    <TypographyComponent component="p" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
      Make your social media accounts stand out
    </TypographyComponent>
  </ListItem>

  <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
    <ListItemIcon sx={{ minWidth: '20px' }}>
      <FiberManualRecordIcon sx={{ fontSize: '10px', color: Colors.black_80 }} />
    </ListItemIcon>
    <TypographyComponent component="p" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
      Get your website found easily online (SEO)
    </TypographyComponent>
  </ListItem>

  <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
    <ListItemIcon sx={{ minWidth: '20px' }}>
      <FiberManualRecordIcon sx={{ fontSize: '10px', color: Colors.black_80 }} />
    </ListItemIcon>
    <TypographyComponent component="p" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
      Design websites that look great and work better
    </TypographyComponent>
  </ListItem>

  <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
    <ListItemIcon sx={{ minWidth: '20px' }}>
      <FiberManualRecordIcon sx={{ fontSize: '10px', color: Colors.black_80 }} />
    </ListItemIcon>
    <TypographyComponent component="p" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
      Create digital marketing plans that bring real results
    </TypographyComponent>
  </ListItem>

  <ListItem sx={{ display: 'flex', alignItems: 'center', padding: 0 }}>
    <ListItemIcon sx={{ minWidth: '20px' }}>
      <FiberManualRecordIcon sx={{ fontSize: '10px', color: Colors.black_80 }} />
    </ListItemIcon>
    <TypographyComponent component="p" sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
      Help manage your brand's online presence
    </TypographyComponent>
  </ListItem>
</List>



  {/* Conclusion */}
  <TypographyComponent sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
    Every business has a unique story. We're here to help tell yours. Whether you're taking your first steps online or looking to reach new heights, our team delivers solutions that work.
  </TypographyComponent>

  <TypographyComponent sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
    No confusing jargon, no complicated processes. Just smart, effective strategies to help your business grow.
  </TypographyComponent>

  <TypographyComponent sx={{ fontWeight: 400, fontSize: { sm: '18px', md: '18px', xs: '12px' }, lineHeight: { sm: '34px', md: '34px', xs: '24px' }, color: Colors.black_80 }}>
    Ready to make your mark online? Let's grow together.
  </TypographyComponent>


        </Box>
      </Box>
      



      <Footer currentPageFooter={'Services'}></Footer>
    </Grid2>

  );
};

export default Service;
