
import React from 'react';
import { Box, Grid2 } from '@mui/material';
import Header from '../Components/Header';
import headerbg from '../Images/headerbg.png'
import Colors from '../Components/Colors';
import Footer from '../Components/Footer';
import TypographyComponent from '../Components/TypographyComponent';
import { useMediaQuery } from '@mui/material';
import bgmob from '../Images/bgmob.jpg';
import { Helmet } from 'react-helmet-async';
import ogImage from '../Images/logo.svg'

const AboutUs = () => {

    const isDesktop = useMediaQuery('(min-width:768px)');

  

    return (
        <Grid2 container sx={{
            width: '100%', height: '100%', overflow: 'hidden',
            justifyContent: 'center', alignItems: 'center'
        }}>
            <Helmet>
                <title>Search Engine Optimization Canada | About us | SEO Services</title>
                <meta name="description" content="Helping Flocks: Expert Search Engine optimization agency dedicated to fuel business growth with digital marketing service . Get to know us." />
                <link rel="canonical" href="https://helpingflock.com/about-us" />
                <meta property="og:title" content="Search Engine Optimization Canada | About Us | SEO Services" />
                <meta property="og:description" content="Helping Flocks is a top digital marketing agency specializing in SEO, PPC, and Social Media Marketing to help your business grow." />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content="https://helpingflock.com/about-us" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Helping Flocks" />
                <script type="application/ld+json">
                    {`
                    {
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "name": "About Us - Helping Flocks",
                        "url": "https://helpingflock.com/about-us",
                        "description": "Helping Flocks is a top digital marketing agency specializing in SEO, PPC, and Social Media Marketing to help your business grow.",
                        "mainEntityOfPage": "https://helpingflock.com/about-us",
                        "potentialAction": {
                        "@type": "ContactAction",
                        "target": "mailto:contact@helpingflocks.com",
                        "query-input": "required name=email"
                        }
                    }
                    `}
                </script>
            </Helmet>
            <h1 style={{ position: 'absolute', left: '-999999px' }}>SEO services</h1>
            
            <Box sx={{
                display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center',
                alignItems: 'center', position: 'relative'
            }} >
                <Box sx={{
                    width: '100%', height: '410px', backgroundImage:  `url(${isDesktop ?headerbg:bgmob})`, backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover', position: 'relative', zIndex: 1, justifyContent: 'center', display: 'flex',
                    alignItems: 'center', flexDirection: 'column',  gap: {sm:'54px',md:'54px',xs:'8px'}
                }} >
                    <Box sx={{
                        position: 'absolute', top: 0, left: 0, width: '100%',
                        height: '100%', backgroundColor: 'rgba(45, 45, 45, 0.48)'
                    }} />
                    <Header currentPage={'About us'} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', zIndex: 2 }} />
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                        alignItems: 'center'
                    }} >
                        <Box sx={{
                            display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100%)', justifyContent: 'center',
                            alignItems: 'center', maxWidth: '90%', maxHeight: '90%', gap: '32px'
                        }} >

                            <TypographyComponent sx={{
                                fontWeight: 700,  fontSize: {sm:'48px',md:'48px',xs:'24px'}, color: '#ffffff', zIndex: 9,
                                width: '100%', lineHeight: {sm:'56px',md:'56px',xs:'28px'}, textAlign: 'center'
                            }}>
                                About Us</TypographyComponent>

                        </Box>

                    </Box>
                </Box>

            </Box>

            <Box sx={{
                height: '95%', display: 'flex', width: '100%', maxWidth: '90%', gap: '34px',
                paddingTop: {sm:'2%',md:'2%',xs:'5%'}, paddingBottom: '3%', flexDirection: 'column'
            }}>
                <Box  sx={{ display: 'flex', flexDirection: 'column', gap: {sm:'16px',md:'16px',xs:'8px'} }}>
                    <TypographyComponent component="h2" sx={{ fontWeight: 500, fontSize: {sm:'32px',md:'32px',xs:'14px'},
                     lineHeight:{sm:'36px',md:'36px',xs:'16px'} , color: Colors.black_80 }}>
                        Who We Are
                    </TypographyComponent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <TypographyComponent component="h3" sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80
                        }}>
                          At Helping Flocks, we have the main competency through which we elevate your brand within this changing dynamic digital world. As the most efficient social media agency in Toronto, we pride ourselves on pivoting businesses digitally through our solutions. Our agile team consists of creative nerds, analysts, industry veterans, and spirited digital marketing professionals. And together, here we strive to outdo even our standing accomplishments.                                                       At Helping Flocks, we have the main competency through which we elevate your brand within this changing dynamic digital world. As the most efficient social media agency in Toronto, we pride ourselves on pivoting businesses digitally through our solutions. Our agile team consists of creative nerds, analysts, industry veterans, and spirited digital marketing professionals. And together, here we strive to outdo even our standing accomplishments.
                          Serving every vertical in the marketplace, we know about the complex and rapid-scaling nature of the current world. With hands-on industry experience, we develop digital assets and strategies such as SEO, social media marketing, and Google Ads aligned with your commercial objectives. Our team at Helping Flocks has vast in-house digital marketing expertise to provide solutions to every digital ethical problem-B-from PPC, SEO, content/visualization creation, to UI/UX design.
                       
                        <TypographyComponent component="p" sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80, paddingTop:'1%'
                        }}>
                            The food for thought would be that we obsess over sharpening our craft, keeping our focus on elevating your productivity and establishing your brand as a badge of honor. Coming closer to your brand vision, we will collaborate in formulating expert strategies that will funnel your business goals and create bridges to connect you with your audience.
                        </TypographyComponent>
                        <TypographyComponent component="p" sx={{
                            fontWeight: 400, fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80, paddingTop:'1%'
                        }}>
                            In Helping Flocks, we live and breathe digital marketing. Other than that, we offer you a well-thought-out growth plan customized for propelling your business forward. Our ability to stay ahead of the trends in social media marketing, Email Marketing, and SEO with zeal by leveraging the best in tools and technology greatly revolutionizes your brand.
                        </TypographyComponent>
                        <TypographyComponent component="p" sx={{
                            fontWeight: 400,fontSize: {sm:'18px',md:'18px',xs:'12px'},
                            lineHeight: {sm:'34px',md:'34px',xs:'24px'}, color: Colors.black_80, paddingTop:'1%'
                        }}>
                            From intelligently designed websites to building a connection with online audiences or conversion optimization, we have tailor-made digital marketing strategies to meet your demands. We move portals to create value in the digital world-from our unflinching commitment to continuous learning whilst keeping up with the trend in global internet marketing. 
                        </TypographyComponent>
                       
                         </TypographyComponent>
                    </Box>
                </Box>
            </Box>
            <Footer currentPageFooter={'About us'}></Footer>
        </Grid2>

    );
};

export default AboutUs;
